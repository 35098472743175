<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <template v-if="show_metabase">
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar dense flat>
              <v-toolbar-title v-if="metabase_dashboards.length === 1">
                {{ metabase_dashboards[0].name }}
              </v-toolbar-title>
              <v-select
                  v-else
                  v-model="selected_dashboard"
                  :items="metabase_dashboards"
                  item-text="name"
                  item-value="id"
                  @change="key = key + 1"
                  hide-details
                  single-line
                  dense
                  style="width: 100px"/>
              <v-spacer/>
              <v-btn text small @click="toMetabase">
                {{ $store.getters.translate("to_metabase") }}
                <v-icon small>mdi-arrow-right</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider/>
            <v-card-text/>
            <div v-if="selected_dashboard" class="grid-col-12 iframe-holder" style="height: 76vh">
              <iframe
                ref="iframe"
                :src="iframe_url"
                style="
                  position: relative;
                  width: 100%;
                  border: none;
                  overflow-y: auto;
                  overflow-x: hidden;
                  height: 100%;
                  border: none;
                  padding-left: 10px;
                  padding-right: 10px;
                  background: white;
                "
                name="bi"
                scrolling="yes"
                frameborder="0"
                marginheight="10px"
                marginwidth="10px"
                width="100%"
                allowfullscreen
                allowtransparency
                :key="key"/>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-container v-else fill-height fluid>
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <v-icon color="primary" x-large>mdi-power-plug-off</v-icon>
          <h4 class="mt-5">
            {{ $store.getters.translate("metabase_configuration_incorrect") }}
          </h4>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../components/commonComponents/BaseBreadcrumb";

export default {
  components: {
    BaseBreadcrumb,
  },
  name: "Dashboard",
  data: () => ({
    page: {
      title: "Dashboard",
      name: "dashboard",
    },
    elementVisible: true,
    iframe_url: "",
    metabase_dashboards: [],
    selected_dashboard: null,
    metabase_secret_key: null,
    key: 1,
    show_metabase: false,
  }),
  mounted() {
    if (this.$store.getters.getMetabaseDashboard) {
      this.selected_dashboard = this.$store.getters.getMetabaseDashboard;
    }
    this.key = this.key + 1;
    this.loadMetabase();
  },
  methods: {
    toMetabase() {
      window.open("https://metabase.pernexus.org", "_blank");
    },
    loadMetabase() {
      if (this.$store.getters.getUser.is_admin) {
        this.$http
          .get(this.$store.getters.appUrl + "v2/metabase-dashboards")
          .then((response) => {
            response.data.forEach((dashboard) => {
              if (dashboard.name) {
                this.metabase_dashboards.push({
                  id: dashboard.id,
                  name: dashboard.name,
                });
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              if (error.response.data.error) {
                this.show_metabase = false;
                this.$store.dispatch("setMetabaseDashboard", null);
              }
            }
            this.loading = false;
          });
      }
      else {
        this.$store.getters.getUser.roles.forEach((role) => {
          if (role.metabase_dashboard_name) {
            this.metabase_dashboards.push({
              id: role.metabase_dashboard,
              name: role.metabase_dashboard_name,
            });
          }
        });
      }
      this.metabase_dashboards = this.$lodash.sortBy(this.metabase_dashboards, "name");
      if (!this.selected_dashboard && this.metabase_dashboards.length > 0) {
        this.selected_dashboard = this.metabase_dashboards[0].id;
      }
      this.show_metabase = true;
    },
    loadDashboard() {
      this.$store.dispatch("setMetabaseDashboard", this.selected_dashboard);
      let jwt = require("jsonwebtoken");
      let payload = {
        resource: {
          dashboard: this.selected_dashboard,
        },
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
      };
      let token = jwt.sign(payload, process.env.VUE_APP_METABASE_SECRET_KEY);
      this.iframe_url = "https://metabase.pernexus.org/embed/dashboard/" + token + "#bordered=false&titled=false";
    },
  },
  watch: {
    selected_dashboard: {
      handler() {
        this.loadDashboard();
      },
      deep: true,
    },
    iframe_url: {
      handler() {
        this.key = this.key + 1;
      },
    },
  },
};
</script>